import {RouteInfo} from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/admin/dashboard/dashboard2',
    title: 'DASHBOARD',
    moduleName: ['dashboard'],
    iconType: 'material-icons-two-tone',
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgArrowDownSrc: '',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [],
  },
  {
    path: '/helpdesk-query',
    title: 'CMS Helpdesk',
    moduleName: ['helpdesk-query'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './helpdesk-query/add-helpdesk-query',
        title: 'Add CMS Helpdesk Query',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './helpdesk-query/all-helpdesk-querys',
        title: 'List of All CMS Helpdesk Queries',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './helpdesk-query/assignee-helpdesk-querys',
        title: 'List of Assigned CMS Helpdesk Queries',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './helpdesk-query/my-helpdesk-querys',
        title: 'List of My CMS Helpdesk Queries',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      // {
      //   path: './master-timetable/add-master-timetable',
      //   title: 'My File Cabinet',
      //   moduleName: ['master-timetable'],
      //   iconType: 'material-icons-two-tone',
      //   icon: 'cloud_upload',
      //   class: '',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: ['All'],
      //   submenu: [],
      //   imgSrc: '',
      //   imgArrowDownSrc: '',
      //   imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      // }
    ],
  },
  {
    path: './',
    title: 'Master Configuration',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './master/module/all-modules',
        title: 'Module',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/sub-module/all-sub-modules',
        title: 'Sub Module',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ]
  },
  {
    path: './user-guide',
    title: 'User Guide',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: 'Helpdesk-Query-Uploaded-Images/IOeImsIVyWWPqGWU.jpg',
        title: 'Helpdesk Module User Guide',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      // {
      //   path: './master/sub-module/all-sub-modules',
      //   title: 'Sub Module',
      //   moduleName: ['master'],
      //   iconType: 'material-icons-two-tone',
      //   icon: 'cloud_upload',
      //   class: '',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: ['All'],
      //   submenu: [],
      //   imgSrc: '',
      //   imgArrowDownSrc: '',
      //   imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      // }
    ]
  },
];
